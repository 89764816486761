<script setup lang="ts">
import { useNotificationStore } from "@/store/notification";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { NOTIFICATION_TYPE } from "@/constants";

const notificationStore = useNotificationStore();

await useLazyAsyncData("notifications", async () => {
  await notificationStore.getNotifications();
});

const { notifications } = storeToRefs(notificationStore);

const unreadTotal = computed(() => notifications.value.filter((n) => !n.read).length);

const getNotificationColour = (type: string) => {
  switch (type) {
    case NOTIFICATION_TYPE.job_expiring:
    case NOTIFICATION_TYPE.job_expired:
    case NOTIFICATION_TYPE.application_removed:
      return "text-danger";
    default:
      return "text-primary";
  }
};

const deleteNotification = async (id: number) => {
  try {
    await notificationStore.deleteNotification(id);
  } catch (e) {
    throw createError({ statusMessage: ERROR_MESSAGES.error_deleting_notification });
  }
};

const markAllAsRead = () => {
  try {
    notificationStore.markAllAsRead();
  } catch (e) {
    throw createError({ statusMessage: ERROR_MESSAGES.error_marking_notifications_as_read });
  }
};

const deleteAllNotifications = async () => {
  try {
    notificationStore.deleteAll();
  } catch (e) {
    throw createError({ statusMessage: ERROR_MESSAGES.error_deleting_notifications });
  }
};

const navigateToPage = (type: string) => {
  switch (type) {
    case NOTIFICATION_TYPE.job_expiring:
      return navigateTo(ROUTES.dashboardJobs);
    case NOTIFICATION_TYPE.job_expired:
      return navigateTo(ROUTES.dashboardArchivedJobs);
    case NOTIFICATION_TYPE.new_message:
      return navigateTo(ROUTES.dashboardChat);
    case NOTIFICATION_TYPE.new_application:
      return navigateTo(ROUTES.dashboardApplications);
    default:
      return;
  }
};
</script>

<template>
  <div class="w-full max-w-sm">
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton class="outline-none rounded-md p-1" @click="markAllAsRead">
        <div class="relative">
          <span class="sr-only">View notifications</span>
          <Icon name="fa6-solid:bell" class="h-6 w-6 text-white hover:text-light-gray" aria-hidden="true" />
          <CircleBadge v-if="unreadTotal" type="danger" class="absolute -top-1 -right-1">{{ unreadTotal }}</CircleBadge>
        </div>
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel class="absolute -left-14 sm:-left-24 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
          <div v-if="notifications.length" class="rounded-lg shadow-lg ring-1 ring-black/5">
            <div class="bg-gray-50 p-4 rounded-t-md">
              <div class="flex justify-between">
                <p class="font-medium text-dark-gray">{{ notifications.length }} Notifications</p>
                <p class="text-xs link" @click="deleteAllNotifications">Delete all</p>
              </div>
            </div>
            <dl class="bg-white divide-y-1 border-light-gray px-2 max-h-96 overflow-y-auto rounded-md relative">
              <transition-group name="list">
                <div
                  v-for="notification in notifications"
                  :key="notification.id"
                  class="px-1 py-2 sm:px-0 flex flex-col"
                  @click="navigateToPage(notification.type)"
                >
                  <div class="cursor-pointer hover:bg-light-gray/20 px-2 py-1 rounded-md">
                    <div class="flex justify-between">
                      <dt v-if="notification.type" class="text-sm font-medium leading-6" :class="getNotificationColour(notification.type)">
                        {{ notification.type.replace(/_/g, " ") }}
                      </dt>
                      <Icon
                        name="fa6-solid:xmark"
                        class="w-4 h-4 cursor-pointer transition-all hover:rotate-180 text-medium-gray"
                        @click.stop="deleteNotification(notification.id)"
                      />
                    </div>
                    <dd class="mt-1 text-sm leading-6 text-dark-gray sm:col-span-2 sm:mt-0">{{ notification.message }}</dd>
                    <p v-if="notification.created_at" class="flex-none text-xs text-medium-gray">
                      <time :datetime="notification.created_at.toString()">{{ dayjs(notification.created_at).toNow(true) }} ago</time>
                    </p>
                  </div>
                </div>
              </transition-group>
            </dl>
          </div>
          <div v-else class="rounded-lg shadow-lg ring-1 ring-black/5">
            <div class="bg-gray-50 p-4 text-medium-gray text-sm rounded-t-md flex justify-center">No notifications to display</div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
